import React from 'react'

interface GridContainerProps {
  children: React.ReactNode
}

const GridContainer = ({ children }: GridContainerProps) => (
  <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">{children}</div>
)

export default GridContainer
