import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from '../../../context/LanguageContext'
import PostPreview from '../../PostPreview'
import GridContainer from '../../GridContainer'
import RenderIf from '../../RenderIf'
import { Node__Post, Query, Paragraph__Blog_Listing } from '../../../types/generated'

export const ParagraphBlogListingFields = graphql`
  fragment ParagraphBlogListingFields on paragraph__blog_listing {
    __typename
    id
    field_title
  }
`

const ParagraphBlogListing = (props: Paragraph__Blog_Listing) => {
  const { field_title: fieldTitle } = props
  const { langcode } = useContext(LanguageContext)
  const data = useStaticQuery<Query>(graphql`
    query BlogListingParagraphQuery {
      allNodePost(sort: { fields: field_date, order: DESC }) {
        nodes {
          ...NodePostFields
        }
      }
    }
  `)

  const allNodePost = data?.allNodePost?.nodes
  // // @todo: remove any type
  const currentNodes = allNodePost?.filter((post: Node__Post) => post.langcode === langcode)

  return (
    <section className="max-w-7xl">
      <RenderIf condition={!!fieldTitle}>
        <h1 className="font-bold text-center text-3xl mb-24">{fieldTitle}</h1>
      </RenderIf>
      <GridContainer>
        {currentNodes.map((post: Node__Post) => (
          <PostPreview post={post} key={post.id} />
        ))}
      </GridContainer>
    </section>
  )
}

export default ParagraphBlogListing
