import React from 'react'
import { Link } from 'gatsby'
import { Node__Post } from '../../types/generated'
import { getPublicImageTransformationUrl } from '../../utils'
import Transformation from '../../types/Transformation'
import RenderIf from '../RenderIf'

interface Props {
  post: Node__Post
}

const PostPreview = ({ post }: Props) => {
  const image =
    post?.relationships?.field_post_image?.relationships?.field_media_image?.uri?.value ??
    'public://2021-08/pexels-lukas-296301.jpg'
  const imageTransformations: Transformation[] = [
    {
      operation: 'scale',
      parameters: {
        width: 600,
      },
    },
  ]

  return (
    <Link
      to={post?.path?.alias ?? '/'}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      <div className="flex-shrink-0">
        <img
          className="h-48 w-full object-cover"
          src={getPublicImageTransformationUrl(image, imageTransformations)}
          alt={post?.relationships?.field_post_image?.thumbnail?.alt ?? ''}
          loading="lazy"
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-indigo-600">
            <time className="font-bold" dateTime={post?.field_date}>
              {post?.field_date}
            </time>
          </p>
          <span className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{post?.title}</p>
            <RenderIf condition={!!post?.body?.summary}>
              <p className="mt-3 text-base text-gray-500">
                {`${post?.body?.summary?.substring(0, 255)}...`}
              </p>
            </RenderIf>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default PostPreview
